<script>
	import mobileScreenshot from '$lib/assets/landing-page/mobile-screenshot.png';
	import desktopScreenshot from '$lib/assets/landing-page/desktop-screenshot.png';
	import heroImgMobile from '$lib/assets/landing-page/mobile-hero.png';

	// mobile screen detection
	let innerWidth = 0;
	$: onMobile = innerWidth <= 600;
</script>

<svelte:window bind:innerWidth />

<div class="hero mega-column">
	{#if onMobile}
		<img src={heroImgMobile} alt="" />
	{:else}
		<img class="desktop-screenshot" src={desktopScreenshot} alt="" />
		<img class="mobile-screenshot" src={mobileScreenshot} alt="" />
	{/if}
</div>

<style>
	.hero {
		position: relative;
	}
	.hero img {
		max-width: 100%;
	}
	.desktop-screenshot {
		width: 80%;
		left: 0px;
	}
	.mobile-screenshot {
		position: absolute;
		z-index: 1;
		right: 0px;
		bottom: 0px;
		width: 25%;
	}
</style>

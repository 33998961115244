<script>
	import LoggedOutHeader from '$lib/components/LoggedOutHeader.svelte';
	import Button from '$lib/components/Button.svelte';
	import feature1Img from '$lib/assets/feature-1.png';
	import feature2Img from '$lib/assets/feature-2.png';
	import feature3Img from '$lib/assets/feature-3.svg';
	import sandpiperLogo from '$lib/assets/sandpiper-logo.png';
	import AppStoreButton from '$lib/components/AppStoreButton.svelte';
	import TextLineDivider from '../lib/components/TextLineDivider.svelte';
	import { goto } from '$app/navigation';
	import Pricing from '$lib/components/Pricing.svelte';
	import HeroImage from '../lib/components/HeroImage.svelte';
	import HeroNotes from '../lib/components/HeroNotes.svelte';
</script>

<svelte:head>
	<title>Your Client Notes, Organized</title>
	<meta
		name="description"
		content="Easy client note tracking. Upload photos and add custom fields. Get started for free."
	/>
</svelte:head>

<LoggedOutHeader />

<main>
	<div class="header-section">
		<div class="large-column center hero" style={'position:relative;z-index:1;'}>
			<h1 class="mega">Your client notes, organized</h1>
			<p class="visually-hidden">
				Client Note Tracker provides easy client note organization. Upload photos and add custom
				fields. Get started for free.
			</p>
			<!-- <p>Simple notes by name and date</p> -->
			<div>
				<Button style="font-size:1.3rem;" on:click={() => goto('/signup')}>Sign up for free</Button>
				<h3>
					Have an account?
					<a style="text-decoration:underline;text-decoration-color:var(--primary)" href="/login"
						>Log in</a
					>
				</h3>
			</div>
			<TextLineDivider>or</TextLineDivider>
			<p>Download the app</p>
			<div class="buttons">
				<AppStoreButton isApple={true} />
				<AppStoreButton isApple={false} />
			</div>
		</div>
		<HeroNotes />
	</div>

	<div class="section">
		<HeroImage />
	</div>

	<div id="features" class="section blue">
		<div class="large-column spaced">
			<div class="img-wrapper img1">
				<img src={feature1Img} alt="" />
			</div>
			<div class="text-column">
				<h2>Organize your clients</h2>
				<p style="margin-top: 10px; margin-bottom: 10px;">
					Save contact info and custom fields for each client.
				</p>
				<p style="margin-top: 10px;">
					Add clients to a searchable list, similar to a contacts app.
				</p>
			</div>
		</div>
	</div>

	<div class="section secondary">
		<div class="large-column spaced">
			<div class="text-column">
				<h2>Track client interactions</h2>
				<p style="margin-top: 10px; margin-bottom: 10px;">
					Create notes for each client to save details about your meet up.
				</p>
				<p style="margin-top: 10px;">Add images to your notes for visual references.</p>
			</div>
			<div class="img-wrapper feature-2">
				<img src={feature2Img} alt="" />
			</div>
		</div>
	</div>

	<div class="section blue blue-2">
		<div class="large-column spaced">
			<div class="img-wrapper feature-3">
				<img src={feature3Img} alt="" />
			</div>
			<div class="text-column">
				<h2>Access notes on all your devices</h2>
				<p style="margin-top: 10px; margin-bottom: 10px;">
					Use the website, iOS app, or Android app to access and update your client info.
				</p>
				<p style="margin-top: 10px;">
					Data automatically syncs across devices using the same login.
				</p>
			</div>
		</div>
	</div>

	<div class="section secondary">
		<div class="pricing-wrapper">
			<h2 id="pricing">Pricing</h2>
			<p>Try all features on our free plan. Subscribe for unlimited notes with Pro.</p>
			<Pricing />
			<div class="pricing-cta-wrapper">
				<Button
					small="true"
					style="font-size:1.3rem;padding:15px 40px;"
					on:click={() => goto('/signup')}>Get started for free</Button
				>
			</div>
		</div>
	</div>

	<div class="section">
		<div class="large-column center">
			<h2 style="margin-bottom:10px;">Support</h2>
			<p style="margin-bottom:0px;">
				Contact <a href="mailto:team@clientnotetracker.com">team@clientnotetracker.com</a> with any feedback
				or questions!
			</p>
		</div>
	</div>

	<div class="section">
		<div class="footer">
			<a href="https://www.sandpiperapps.com/" target="_blank" rel="noopener noreferrer">
				<img src={sandpiperLogo} alt="" />
			</a>
			<h3>
				Created by <a
					href="https://www.sandpiperapps.com/"
					target="_blank"
					rel="noopener noreferrer">Sandpiper Apps</a
				>
			</h3>
		</div>
	</div>
</main>

<style>
	.pricing-wrapper {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.pricing-wrapper h2 {
		text-align: center;
		padding: 10px 0px;
		padding-bottom: 0px;
	}
	.pricing-wrapper p {
		text-align: center;
		margin-bottom: 30px;
		padding: 0px 20px;
	}
	.center {
		display: flex;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		align-items: center;
	}
	p {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}
	.header-section {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 80px 0px;
		padding-bottom: 40px;
		position: relative;
		max-width: 100%;
		overflow: hidden;
	}
	.mega {
		font-size: 4rem;
		margin-bottom: 60px;
		padding: 0px 20px;
		border-radius: 10px;
		font-weight: 800;
	}
	.section {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0px;
	}
	.pricing-cta-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}
	.secondary {
		background-color: #fcfcfc;
	}
	.blue {
		/* background-color: #f3f8ff;
		background-color: #f0f5fb; */
		background: linear-gradient(60deg, #f0f5fb, #fff);
		color: #4196f7;
		color: var(--primary);
		border-color: #4d9ffe;
	}
	.blue-2 {
		background: linear-gradient(290deg, #f0f5fb, #fff);
	}
	.spaced {
		display: flex;
		gap: 90px;
		width: 1000px;
		align-items: center;
	}

	.text-column h2 {
		/* font-size: 2.5rem; */
		font-weight: 800;
	}

	.text-column p {
		/* font-size: 1.2rem; */
		color: var(--text);
	}

	.spaced img {
		/* border: 3px solid rgb(53, 53, 53); */
		padding-top: 2px;
		/* background-color: white; */
		border-radius: 4px;
		max-width: 100%;
	}
	.text-column {
		flex-grow: 1;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 30px;
	}
	h2 {
		margin-top: 0px;
		font-size: 2rem;
	}
	.img-wrapper {
		/* min-width: 200px; */
		max-width: 300px;
		/* width: 200px; */
	}
	.img-wrapper.img1 {
		max-width: 340px;
	}
	.footer {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.footer img {
		width: 40px;
		margin-right: 10px;
	}

	.hero {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0));
	}

	.feature-2 {
		margin-right: 50px;
		margin-left: 20px;
	}

	.feature-3 {
		margin-top: 30px;
		margin-right: 40px;
	}

	@media screen and (max-width: 700px) {
		.spaced {
			gap: 30px;
		}
	}

	@media screen and (max-width: 550px) {
		.mega {
			font-size: 3rem;
		}
		.spaced {
			flex-direction: column-reverse;
			gap: 50px;
			text-align: center;
		}
		.secondary .spaced {
			flex-direction: column;
		}
		.text-column {
			padding-bottom: 0px;
		}
		.text-column p {
			font-size: 1.2rem;
			padding: 0px 20px;
		}
		.pricing-wrapper p,
		.large-column p {
			font-size: 1.2rem;
		}
		h2 {
			margin-top: 30px;
		}
		.feature-2,
		.feature-3 {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
</style>

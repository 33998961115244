<div class="wrapper">
	<div class="price-tiles">
		<div class="tile light">
			<h3 class="title">Starter</h3>
			<!-- <h3 class="title">Starter 🌱</h3> -->
			<h3 class="price">Free</h3>
			<ul>
				<li>Try app for free to see if it's right for you</li>
				<li>No payment information needed</li>
				<li><strong>Full feature access</strong> with limit of 50 notes</li>
			</ul>
		</div>
		<div class="tile">
			<h3 class="title">Pro</h3>
			<!-- <h3 class="title">Pro ✨</h3> -->
			<h3 class="price" style="padding-bottom: 0px;">
				$2.99 <span class="time">/ month</span> <span class="or">or</span>
			</h3>
			<h3 class="price" style="padding-top: 6px;">$23.99 <span class="time">/ year</span></h3>
			<ul>
				<li>Unlimited notes</li>
				<li>Full feature access</li>
				<li>Support further development of the application</li>
			</ul>
		</div>
	</div>
</div>

<style>
	.wrapper {
		display: flex;
		justify-content: center;
	}
	.price-tiles {
		width: 95%;
		display: grid;
		grid-template-columns: 50% 50%;
		gap: 15px;
	}
	.tile {
		border: 1px solid #ddd;
		/* border-top: 15px solid var(--primary); */
		padding: 30px 20px;
		padding-bottom: 60px;
		border-radius: 10px;
		background-color: white;
		box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
	}

	h3 {
		margin: 0px;
		font-size: 1.6rem;
	}
	h3.title {
		margin-bottom: 16px;
		color: var(--primary);

		/* text-decoration: solid underline rgba(50, 110, 240, 0.8) 3px; */
	}

	h3.price {
		margin: 0px -20px;
		padding: 20px;
		font-size: 1.4rem;
		font-weight: 700;
		background-color: #f3f8ff;
	}
	h3.price span.or {
		color: grey;
		font-weight: 400;
		/* font-style: italic; */
		font-size: 1.1rem;
	}
	h3.price span.time {
		font-size: 1.2rem;
	}
	ul {
		font-size: 1.2rem;
		padding-left: 30px;
		margin-top: 20px;
	}
	li {
		margin-bottom: 8px;
	}
	@media screen and (max-width: 550px) {
		.price-tiles {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 90%;
		}
		.tile {
			padding: 20px;
			box-sizing: border-box;
			width: 100%;
		}
		ul {
			font-size: 1.2rem;
			padding-left: 20px;
		}
	}
</style>

<script>
	import { getPrettyDate } from '$lib/helpers/misc.js';

	const today = new Date();
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);
</script>

<div class="note note-1" aria-hidden="true">
	<h3>{getPrettyDate(today)}</h3>
	<p>Used our #2 and #5 toners. Learned that Dana is a big Detroit Lions fan.</p>
</div>

<div class="note note-2" aria-hidden="true">
	<h3>{getPrettyDate(yesterday)}</h3>
	<p>Great session with Sam, looking to shift focus to core and back strength.</p>
</div>

<div class="blue-box blue-1"></div>

<div class="blue-box blue-2"></div>

<div class="blue-box blue-3"></div>

<style>
	.note {
		padding: 20px 30px;
		border: 1px solid rgba(50, 110, 240, 0.6);
		border-radius: 30px;
		position: absolute;
		opacity: 1;
		width: 300px;
		z-index: 0;
		background-color: #fff;
		transition: opacity 0.2s linear;
	}

	.note p,
	.note h3 {
		color: var(--primaryDark);
		opacity: 0.7;
	}

	.note p {
		color: var(--primary);
		opacity: 0.8;
	}

	.note-1 {
		padding-right: 60px;
		top: 40%;
		right: -40px;
	}

	.note-2 {
		top: 20%;
		padding-left: 60px;
		left: -40px;
	}

	.blue-box {
		z-index: -1;
		position: absolute;
		width: 300px;
		height: 200px;
		border: 1px solid var(--primary);
		border-radius: 30px;
		opacity: 0.15;
	}

	.blue-1 {
		top: 10%;
		left: -60px;
	}

	.blue-2 {
		top: 10%;
		right: -50px;
		width: 180px;
		height: 120px;
	}

	.blue-3 {
		top: 50%;
		left: -40px;
		height: 100px;
		width: 200px;
	}

	@media screen and (max-width: 800px) {
		.blue-1 {
			top: 3%;
			left: -80px;
		}
		.note {
			opacity: 0;
		}
		.blue-3 {
			display: none;
		}
	}
</style>
